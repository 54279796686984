<template>
    <div class="cron-modal">
        <el-dialog
            class="comn_dialog"
            :visible.sync="visible"
            width="700px"
            :modal-append-to-body= 'false'
        >
        <p slot="title">
            控制周期
        </p>
        <div class="dialog_content_box">
            <div>
                <span>时间选择：</span>
                <el-time-picker
                    is-range
                    v-model="formInfo.flightTime"
                    @change="timeChange"
                    value-format="HH:mm"
                    format="HH:mm"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围">
                </el-time-picker>
            </div>
            <div>
                <span>日期选择：</span>  
                <el-radio-group v-model="formInfo.date" size="small" @change="dateChange">
                <el-radio :label="true" border class="radioBg">每天</el-radio>
                <el-radio :label="false" border class="radioBg">自定义</el-radio>
                </el-radio-group>  
            </div>   
            <transition name="fade">
                <ul v-show="isWeeksDome">
                    <li 
                    v-for="item of weekDays"
                    :key="item.label"
                    :class="activeWeek.includes(item.value)? 'baseColor ctr_box_border': ''"
                    @click="weeksCheck(item)"
                    >{{ item.label }}</li>
                </ul>
            </transition>
            <div class="but_main_box">
                <el-button size="small" @click="cancel">取消</el-button>
                <el-button size="small" @click="save" type="success" class="but_box">保存</el-button>
            </div>
        </div>  
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            cronValue: {
                type: String,
                default() {
                    return "* * * * * ? *"; // 默认选中每一个radio-group的第一项
                }
            },
            cronVisible: {
                type: Boolean,
                default() {
                    return false;
                }
            }
        },
        data() {
            return{
                 weekDays:[
                    {
                        value: '2',
                        label: '一'
                    },
                    {
                        value: '3',
                        label: '二'
                    },
                    {
                        value: '4',
                        label: '三'
                    },
                    {
                        value: '5',
                        label: '四'
                    },
                    {
                        value: '6',
                        label: '五'
                    },
                    {
                        value: '7',
                        label: '六'
                    },
                    {
                        value: '1',
                        label: '日'
                    },
                 ],
                 formInfo:{
                     flightTime: '',
                     date:''
                 },
                 activeWeek:[],
                 minutesText:"",
                 hoursText: '',
                 daysText: '',
                 weeksText:'',
                 isWeeksDome: false
            }
           
        },
        watch: {
            visible: {
                handler() {
                    let label = this.cronValue;
                    if (label) {
                        this.MHReverseExp(label);
                        this.daysReverseExp(label);
                        this.weeksReverseExp(label);
                    }
                }
            }
        },
        computed:{
            visible: {
                get() {
                    return this.cronVisible;
                },
                set(val) {
                    this.$emit("updateCornVisible", val);
                }
            },
            cronStr() {
                return `* ${this.minutesText || "*"} ${this.hoursText || "*"} ${this.daysText || "*"} * ${this.weeksText || "?"} *`;
                    
                    
            }

        },
        methods:{
            weeksReverseExp(cron){
               let val = cron.split(" ")[5];
               if(val.includes(',')){
                  this.activeWeek= val.split(',')
                  this.$set(this.formInfo,'date',false)
                  this.isWeeksDome = true
                 
                  return
               }
               this.$set(this.formInfo,'date',true)
               this.isWeeksDome = false
            },
            MHReverseExp(cron){
               let cronArr = cron.split(" ")
               let M = cronArr[1],H = cronArr[2];
               if(M.includes('-') && H.includes('-')){
                   
                   let resultStart = `${H.split("-")[0]}:${M.split("-")[0]}`
                   let resultEnd = `${H.split("-")[1]}:${M.split("-")[1]}`
                   this.$set(this.formInfo,'flightTime',[resultStart,resultEnd])
               }else{
                  this.$set(this.formInfo,'flightTime','')   
               }
            },
            daysReverseExp(cron){
               let val = cron.split(" ")[3];
               if(val.includes('*')){
                   this.$set(this.formInfo,'date',true)
               }
            },
            weeksCheck(item){
                const index = this.activeWeek.indexOf(item.value)
                if(index == -1){
                    this.activeWeek.push(item.value)
                }else{
                    this.activeWeek.splice(index,1)
                }
                this.weeksText = this.activeWeek.join(',')
                    
            },
            timeChange(data){
                if(data && data.length){
                    let hour = [],minutes = []
                    data.forEach(str => {
                         let index = str.indexOf(':')
                         let mData = str.slice(index+1)
                         if(mData.indexOf('0') === 0){
                           mData= mData.slice(1)
                         }
                         hour.push(str.slice(0,index))
                         minutes.push(mData)
                    })
                    this.minutesText = minutes[0]+ "-" +minutes[1]
                    this.hoursText = hour[0]+ "-" +hour[1]
                }
            },
            dateChange(val){
                if(val){
                    // 每天
                    this.isWeeksDome = false
                    this.daysText = '*'
                    this.weeksText = ''
                }else{
                    // 自定义周
                    this.isWeeksDome = true
                    this.daysText = '?'
                   
                }

            },
            cancel() {
              this.$emit("resetCornPopup");
            },
            save() {
              this.$emit("submitCornPopup", this.cronStr);
            }
        }
    }
</script>

<style lang="scss" scoped>
.radioBg{
    background: #344552;
    .comn_dialog{
        background: rgba( #000000, 0.1);
    }
   
}
 .dialog_content_box{
       width: 70%;
       margin: 0 auto;
       & > div {
           margin-bottom: 15px;
           span{
               color: #fff;
           }
       }
       & > ul {
           display: flex;
           margin-left: 70px;
           & > li {
              width: 28px;
              height: 28px;
              border-radius: 14px;
              line-height: 28px;
              text-align: center;
              border: 1px solid #999;
              color: #999;
              font-size: 14px;
              margin-right: 15px;

           }
       }
       .but_main_box{
           text-align: right;
           margin-top: 30px;
           .but_box{
               color: #fff;
               width: 80px;
           }
       }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>