<template>
    <div class="intelligentCtl_main_box wh100">
        <div class="left_box">
           <div class="plan_title_box boxBg">
              <span>控制计划</span>
              <span class="el-icon-plus cp" @click="addPlan"></span>
           </div>
           <ul class="boxScroll">
             <li 
             v-for="item of planList"
             :key="item.key"
             @click="subPlanClick(item.id)"
             :class="[menuActive === item.id? 'intCtl-menu-bg-image':'baseBg','cp']">
              <span :class="['sinleline',menuActive === item.id?'baseColor': '']">{{ item.name }}</span> 
              <icon-popover class="edit_icon_box"></icon-popover>
             </li>
           </ul>
        </div>
        <div class="right_box boxBg">
             <div class="intCtl-border-bg-image">
               <div class="select_box">
                 <!-- <el-cascader
                  v-model="value"
                  :options="options"
                  :props= 'selectProps'
                  ></el-cascader> -->
                 <!-- <el-select v-model="value" class="devSelect_box selectDev" placeholder="请选择控制器类型" size="mini">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                 </el-select> -->
                 <!-- <el-menu
                default-active="2"
                class="devMenus"
                background-color="#545c64"
                text-color="#fff"
                active-text-color="#ffd04b">
                <template v-for="item of menus">
                  <el-submenu :index="item.baseId">
                    <template slot="title">
                     
                      <span>{{ item.baseName }}</span>
                    </template>
                    <template v-if="item.areas && item.areas.length">
                        <el-menu-item v-for="val of item.areas" :index="val.devId">{{ val.devName }}</el-menu-item>
                    </template>
                    
                   </el-submenu>
                </template>
                
                </el-menu> -->
               </div>
               <ul class="boxScroll actuator_box">
                  <li 
                    v-for="item of devList"
                    :key="item.id"
                    @click="devCheck(item.id)"
                  >
                    <div>
                       <div :class="['devIcon', devActive.includes(item.id)?'intCtl-dev-icon-image':'baseBg']">
                         <span :class="['iconfont', 'icon-chongqingjiance-jian', devActive.includes(item.id)?'baseColor':'']"></span>
                       </div>
                       <p :class="devActive.includes(item.id)?'baseColor':''">{{ item.name }}</p>
                    </div>
                    <div :class="['checkIcon', devActive.includes(item.id)? 'ctr_box_border': 'baseBorderColor']">
                      <span :class="['el-icon-check',devActive.includes(item.id)?'baseColor':'']"></span>
                    </div>
                  </li>
                 

               </ul>
             </div>
             <div class="intCtl-box-bg-image">
                  <div class="intCtl_from_box intCtl-from-bg-image">
                    <el-form :model="ctlForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="有效期" prop="time">
                             <el-date-picker
                            v-model="ctlForm.time"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            >
                            </el-date-picker>
                        </el-form-item>
                         <el-form-item label="控制动作" >
                             <el-select v-model="ctlForm.start" class="selectAction" placeholder="请选择">
                              <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                              </el-option>
                            </el-select>
                        </el-form-item>
                         <el-form-item label="结束动作">
                             <el-select v-model="ctlForm.end" class="selectAction" placeholder="请选择">
                                <el-option
                                  v-for="item in options"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                                </el-option>
                             </el-select>
                        </el-form-item>
                         <el-form-item label="控制周期" >
                             <inputCron v-model="ctlForm.cron"></inputCron>
                        </el-form-item>
                         <el-form-item class="save_but_box">
                            <div class="but_base cp">保存</div>
                        </el-form-item>
                    </el-form>  
                      
                  </div>
             </div>
        </div>
    </div>
</template>

<script>
    // import iconPopover from '@/components/iconPopover.vue'
    import IconPopover from '@/components/localComponent/iconPopover.vue'
    import inputCron from './components/inputCron'
    import { devCodeAndId } from '@/libs/util'
    import config from '@/config'
    export default {
        components:{
            IconPopover,
            inputCron
        },
        data() {
            return {
               devId: '',
               actuatorId: '',
               menus:[],
               selectProps: {
                   children: 'areas',
                   value
               },
               planList:[
                 {
                   name: '控制一号计划',
                   id:'1'
                 }
               ],
               menuActive: '',
               options: [{
                    value: '选项1',
                    label: '黄金糕'
                    }, {
                    value: '选项2',
                    label: '双皮奶'
                    }, {
                    value: '选项3',
                    label: '蚵仔煎'
                    }, {
                    value: '选项4',
                    label: '龙须面'
                    }, {
                    value: '选项5',
                    label: '北京烤鸭'
               }],
               value: '', 
               devList:[
                 {
                   name: '风机',
                   id:'1'
                 },
                 {
                   name: '卷帘机',
                   id:'2'
                 },
                 {
                   name: '灯开关',
                   id:'3'
                 },
                 {
                   name: '卷模机',
                   id:'4'
                 }
                 ,
                 {
                   name: '喷逛',
                   id:'5'
                 },
                 {
                   name: '滴灌',
                   id:'6'
                 }
                 ,
                 {
                   name: '地方',
                   id:'7'
                 },
                 {
                   name: '大佛',
                   id:'8'
                 },
               ],
               devActive: [],
               ctlForm:{
                  time: '',
                  start:'',
                  end:'',
                  cron:'* 21-21 15-16 ? * 3,6 *'
               },
               rules:{
                 time: [
                    { required: true, message: '请输入有效期', trigger: 'blur' },
                 ]
               },
               options: [{
                  value: '选项1',
                  label: '黄金糕'
                }, {
                  value: '选项2',
                  label: '双皮奶'
                }, {
                  value: '选项3',
                  label: '蚵仔煎'
                }, {
                  value: '选项4',
                  label: '龙须面'
                }, {
                  value: '选项5',
                  label: '北京烤鸭'
               }],
            }
        },
        created(){
            const queryData = this.$route.query
            const data = queryData.data || ''
            let result = devCodeAndId(data)
            if(result){
               let { devId, devCode} = result
               this.devId = devId
               this.actuatorId = devCode
            }
            // this.reqEntBaseAreaDeviceList()
        },
        methods:{
          // 基地区域设备
          async reqEntBaseAreaDeviceList(){
                const menuDevType = config.menuDevType
               const res = await this.$api.EntBaseAreaDeviceList({devTypes:[menuDevType['采控设备'],menuDevType['控制设备']]})
               if(res && res.length){
                   
                   res.forEach(item => {
                        if(item.areas && item.areas.length){
                            let areas = []
                            item.areas.forEach(val => {
                                 
                                 if(val.devs && val.devs.length){
                                     val.devs.forEach(i => {
                                         areas.push(i)
                                     })
                                 }
                            })
                            item.areas = areas
                        }
                   })
                   this.menus = res
               }

          },
          onSelect(val) {
              //  this.visible= false
              //  this.$emit('change',{
              //      data: val,
              //      i: this.i
              //  })
          },
          addPlan() {
            let resultData = this.planList.filter(item => !item.id)
            if(resultData && resultData.length){
                this.$notify({
                  title: '提醒',
                  message: '已创建新计划，请先完成计划，再创建',
                  type: 'warning',
                  position: 'top-left'
                });
            }else{
              const defaultPlan= {
                name: '控制计划-自定义名称',
                id:''
              }
              this.menuActive= ''
              this.planList.push(defaultPlan)

            }
            
             
          },
          subPlanClick(id){
              this.menuActive = id
          },
          devCheck(id){
            const isCheck = this.devActive.indexOf(id)
            if(isCheck === -1){
                this.devActive.push(id)
            }else {
               this.devActive.splice(isCheck,1)
            }
          }
        }
    }
</script>

<style lang="scss" scoped>
.intelligentCtl_main_box{
   display: flex;
    box-sizing: border-box;
    padding: 20px 20px 0 20px;
   .left_box{
       width: 270px;
       height: 100%;
       flex-shrink: 0;
       .plan_title_box{
           width: 100%;
           height: 40px;
           line-height: 40px;
           font-size: 16px;
           border-radius: 8px 8px 0 0;
           text-align: center;
           position: relative;
           & > span:last-child{
              font-size: 18px;
              position: absolute;
              top: 10px;
              right: 12px;
           }
       }
       & > ul {

         height: calc(100% - 40px);
         li {
            width: 100%;
            height: 40px;  
            line-height: 40px;
            text-align: center;
            margin-top: 8px;
            position: relative;
            & > span:first-child{
                max-width: 170px;
            }
            .edit_icon_box{
               position: absolute;
               right: 12px;
            }
         }
         .baseBg{
            background: url('../../../assets/imgs/control/defalut/navigation_bg.png') no-repeat;
            background-size: 100% 100%;
         }
       }
    }
    .right_box{
       flex: 1;
       min-width: 0;
       height: 100%;
       border-radius: 8px;
       margin-left: 10px;
       box-sizing: border-box;
       padding: 20px;
       display: flex;
       justify-content: space-between;
       & > div:first-child{
          width: 200px;
          height: 100%;
          .select_box{
             width: 100%;
             height: 50px;
             position: relative;
             z-index: 100;
            //  display: flex;
            //  justify-content: center;
            //  align-items: center;
             .devMenus{
                height: 100%;
             }
          }
         .actuator_box {
            width: 100%;
            height: calc(100% - 50px);
            & > li {
                width: 80%;
                height: 116px;
                margin: 0 auto;
                position: relative;
                & >div:first-child{
                     text-align: center;
                     color: #999;
                     font-size: 16px;
                    .devIcon{
                      width: 60px;
                      height: 60px;
                      margin: 20px auto 10px auto;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      & > span{
                        font-size: 30px;
                        color: #999;
                      }

                    }
                    .baseBg{
                        background: url('../../../assets/imgs/control/defalut/circle.png') no-repeat;
                        background-size: 100% 100%;
                    }
                }
                .checkIcon{
                   width: 14px;
                   height: 14px;
                   border-radius: 100%;
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   position: absolute;
                   right: 15px;
                   top: 0;
                   & > span{
                     font-size: 12px;
                     color: #999;
                   }
                }
                .baseBorderColor{
                   border: 1px solid #999;
                }
             }
             & > li:not(:last-child)::before{
                   width: 100%;
                   height: 1px;
                   display: block;
                   background: linear-gradient(
                    to right, 
                    rgba(0,0,0,0) 0%,  
                    #fff 50%, 
                    rgba(0,0,0,0) 100%);
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    content: '';
               }
          }
       }
       & > div:last-child{
          flex: 1;
          margin-left: 20px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .intCtl_from_box{
             width: 627px;
             height: 384px;
             display: flex;
             align-items: center;
             justify-content: center;
             .save_but_box{
                 .but_base {
                    width: 130px;
                    font-size: 16px;
                    height: 32px;
                    margin-left: auto;
                    line-height: 32px;
                    text-align: center;
                    border-radius: 5px;
                    
                 }
              }
              .selectAction{
                width: 100%;
              }
          }
          
       }
    }
}
</style>