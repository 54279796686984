<template>
  <div class="test">
    <el-input
      type="text"
      v-model="cronValue"
      autocomplete="off"
      class="cronInput"
      @input="showCronModal"
    >
      <el-button
        slot="append"
        @click="showCronModal"
        class="el-icon-more"
        title="设置cron表达式"
      >
      </el-button>
    </el-input>
    <test
      :cronValue="cronValue"
      :cronVisible="cronVisible"
      @resetCornPopup="resetCornPopup"
      @submitCornPopup="submitCornPopup"
      @updateCornVisible="updateCornVisible"
    >

    </test>
    <!-- <cron-modal
      :cronValue="cronValue"
      :cronVisible="cronVisible"
      @resetCornPopup="resetCornPopup"
      @submitCornPopup="submitCornPopup"
      @updateCornVisible="updateCornVisible"
    ></cron-modal> -->
  </div>
</template>
<script>
// import cronModal from "./cronModel.vue";
import test from './test.vue'
export default {
  components: {

    test
  },
  props:{
    value: {
        type: String,
    }
  },
  data() {
    return {
    //   FormInPopup: {
    //     cronExpression: "0/5 * * * * ? *"
    //   },
      cronVisible: false, // cron表达式弹框
      cronValue: "" // cron的初始值, 如 0/5 * * * * ? * 表示每隔5秒运行一次
    };
  },
  watch:{
     value:{
         handler(val){
            this.cronValue = val
         }
     }
  },
  created() {
     this.cronValue = this.value
  },
  methods: {
    updateCornVisible(val) {
      this.cronVisible = val;
    },
    showCronModal() {
      // 打开cron表达式的弹框
      this.cronVisible = true;
      
    },
    resetCornPopup() {
      this.cronVisible = false;
    },
    submitCornPopup(val) {
      // 提交cron的弹窗
      this.$emit('input',val)
      this.cronVisible = false;
    }
  }
};
</script>
<style>

.test .cron-value_input {
  width: 100%;
}
</style>
